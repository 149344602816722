.round {
    width: 1rem;
    height: 1rem;
    border: 1px solid black;
    border-radius: 50%;
}

.round.green {
    background-color: green;
}

.round.blue {
    background-color: blue;
}

.round.yellow {
    background-color: yellow;
}

.round.red {
    background-color: red;
}

.trading-card {
    background-color: #e9ecef !important;
    border: 1px solid black !important;
    height: 5rem !important;
    width: 8rem !important;
}

.warningBg {
    background: rgba(255, 191, 0, 0.764) !important;
}

.redBg {
    background: rgba(255, 0, 0, 0.25) !important;
}
.orangeBg {
    background: rgba(255, 165, 0, 0.25) !important;
    /* make text bold */
    font-weight: bold !important;
}

b {
    font-weight: bold !important;
}

.bold {
    font-weight: bold !important;
}

.f16 {
    font-size: 16px !important
}

.redtext {
    color: #ff0000 !important;
}

.mini-stat {
    height: 160px !important;
}

#2faModal .btn-close {
    display: none;
}

.red-dot::after {
    content: '';
    position: absolute;

    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
}

.addCRcard {
    background: #eaeaea !important;
}

.CRcard {
    background: #e1eed8 !important;
}

.error-text {
    color: #ec4561 !important;
}
.success-text {
    color: #00aa00 !important;
}
.huge-input {
    font-size: 32px;
    width: 100%;
    box-sizing: border-box; /* Ensures padding and border are included in the width */
}
.leaflet-control-attribution.leaflet-control {
    display: none !important;
    /*remove ukraine flag */
    visibility: hidden !important;
}

.status-container {
    position: relative;
    display: inline-block;
}

.status-text {
    display: inline;
    /* Display the status text inline */
}

.tooltip {
    visibility: hidden;
    opacity: 0;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    min-width: 200px;
    /* Position above the container */
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    white-space: nowrap;
}

.status-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.btn-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btn-group-hz {
    display: flex;
    align-items: center;
}

.btn-wrapper {
    display: flex;
    align-items: center;
}

.btn-wrapper input[type="radio"] {
    display: none;
    /* Hide the actual radio button */
}

.btn-wrapper label {
    padding: 10px 20px;
    margin: 5px;
    min-width: 200px;
    /* Adjust this value for consistent button width */
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.btn-wrapper .extra-info {
    margin-left: 10px;
    /* Adjust spacing between label and extra information */
    color: #888;
    /* Adjust color as needed */
}

.btn-wrapper input[type="radio"]:disabled+label {
    background-color: #e0e0e0;
    color: #a0a0a0;
    border-color: #a0a0a0;
    cursor: not-allowed;
}

.btn-wrapper input[type="radio"]:checked+label {
    background-color: #70ad47;
    color: white;
    border-color: #70ad47;
}

.btn-wrapper label:hover {
    background-color: #5e8d3e;
    /* Change background color on hover */
    color: white;
    border-color: #5e8d3e;
}

.btn-wrapper input[type="radio"]:checked+label {
    background-color: #70ad47;
    color: white;
    border-color: #70ad47;
}

.btn.red:hover {
    background-color: #d9534f;
    border-color: #ccc;
}

.btn.green {
    color: #70ad47;
    /* Red */
    /* background-color: white; */
}

.btn.red {
    color: #d9534f;
    /* Red */
    /* background-color: white; */
}

.btn-wrapper input[type="radio"]:checked+label {
    background-color: #70ad47;
    color: white;
    border-color: #70ad47;
}

.btn-wrapper.green input[type="radio"]:checked+label {
    background-color: #70ad47;
    color: white;
    border-color: #70ad47;
}

.btn-wrapper.red input[type="radio"]:checked+label {
    background-color: #d9534f;
    color: white;
    border-color: #d9534f;
}

.activity-checkout {
    position: relative;
    /* Ensure .activity-checkout has a relative position */
}

.activity-checkout .checkout-item {
    position: relative;
    padding-bottom: 24px;
    padding-left: 35px;
    border-left:0px solid #70ad47;
}

/* triangle shape */
.activity-checkout .checkout-item::before {
    content: '';
    position: absolute;
    top: 50px;
    left: -11px;
    /* Adjust horizontal position as needed */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid #70ad47;
}
/* vertical line to triangle */
.activity-checkout .checkout-item::after {
    content: '';
    position: absolute;
    top: 50px;
    /* Position just below the triangle */
    left: -1px;
    /* Center horizontally */
    transform: translateX(-50%);
    width: 2px;
    /* Width of the vertical line */
    height: calc(100% - 10px);
    /* Height of the line adjusted to exclude triangle */
    background-color: #70ad47;
    /* Color of the line */
}
/* horizontal line */
.activity-checkout .checkout-item .horizontal-line {
    position: absolute;
    top: 158px;
    /* Adjust to where you want the horizontal line */
    left: 0px;
    width: 90% ;
    height: 2px;
    /* Height of the horizontal line */
    background-color: #70ad47;
    /* Color of the horizontal line */
}
/* second triangle */
.activity-checkout .checkout-item .horizontal-line::before {
    content: '';
    position: absolute;
    top: -20px;
    right: -9px;
    /* Adjust horizontal position as needed */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid #70ad47;
}
.activity-checkout .checkout-item .horizontal-line::after {
    content: '';
    position: absolute;
    top: -15px;
    right: 1px;
    /* Center horizontally */
    transform: translateX(50%);
    width: 2px;
    height: 15px;
    background-color: #70ad47;
    z-index: 1;
    /* Ensure the line is above other content */
}
.activity-checkout .checkout-item:last-child .horizontal-line,
.activity-checkout .checkout-item:last-child::before,
.activity-checkout .checkout-item:last-child::after
{
    display: none;
    /* Hide triangle, vertical line, and horizontal line for the last item */
}
.p-datatable th.text-end .p-column-header-content {
    display: block;
    /* text-align: right; */
    /* Or use 'inline', 'initial', or any appropriate display value */
}

svg.p-icon.p-sortable-column-icon {
    width: 10px;
    height: 10px;
}

.p-datatable th.text-end.p-sortable-column .p-column-header-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.w-100px {
    width: 100px !important;
}
.w-125px {
    width: 125px !important;
}
.danger-important-text {
    /* bold red text dangerous*/
    font-weight:1000 !important;
    color:#E53935 !important;
}